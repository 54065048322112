.loginForm {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 2px 2px lightgray;
    border-radius: 5px;
    background: white;
    padding: 30px;
    margin-top: 30px;
}

.loginLogo {
    max-width: 370px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 220px;
    height: auto;
}

.offlineKey {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
}

.loginForm .form__custom-button {
    margin-top: 50px;
}

p:focus {
    background-color:lightsteelblue;
}
